<template>
  <div>
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <p class="title is-4 has-text-right">
        {{ sessionTitle }}
      </p>
      <b-table
        :data="schoolFeesClassGroupReport"
        :paginated="isPaginated"
        hoverable
      >
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ schoolFeesClassGroupReport.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Name" v-slot="props">
          {{ props.row.name }}
        </b-table-column>

        <b-table-column label="Total Revenue" v-slot="props">{{
          convertToCurrency(props.row.total, '₦')
        }}</b-table-column>

        <b-table-column label="Paid" v-slot="props">{{
          convertToCurrency(props.row.paid, '₦')
        }}</b-table-column>

        <b-table-column label="Balance" v-slot="props">{{
          convertToCurrency(props.row.balance, '₦')
        }}</b-table-column>

        <b-table-column label="Actions" width="160" v-slot="props">
          <action-buttons
            @initialize-info="initializeInfo(props.row)"
            :show-update="false"
            :show-delete="false"
          />
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import { toCurrency } from '@/assets/js/currency_formatter'

export default {
  data() {
    return {
      schoolId: null,
      termId: null,
      pageTitle: 'School Fees Class Group Report',
      schoolFeesClassGroupReport: [],
      isPaginated: false,
      sessionTitle: '',
    }
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Fee Management',
        route: `/school/${this.$route.params.school_id}/fee_management`,
      },
      {
        name: 'New School Fee',
        route: `/school/${this.$route.params.school_id}/school_fee/new`,
      },
      {
        name: 'School Fees Report',
        route: `/school/${this.schoolId}/school_fees_report`,
      },
    ])

    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.sessionTitle = term.session_title

      this.$apollo.addSmartQuery('schoolFeesClassGroupReport', {
        query: gql`
          query SchoolFeesClassGroupReportQuery($termId: ID!) {
            schoolFeesClassGroupReport(termId: $termId)
          }
        `,
        error(error) {
          console.log(error.message)
        },
        variables: {
          termId: this.termId,
        },
      })

      this.$apollo.queries.schoolFeesClassGroupReport.refetch()
    })
  },
  methods: {
    convertToCurrency(value, symbol) {
      return toCurrency(value, symbol)
    },
    initializeInfo(class_group) {
      this.$router.push(
        `/school/${this.schoolId}/school_fees_school_class_report/${class_group.id}`
      )
    },
  },
}
</script>
